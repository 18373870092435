<template>
  <div>
    <div class="tg_menu_ber_boolong_nsx">
      <div class="container">
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push({name: 'calendar'})">Booking calendar</a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push({name: 'booking'})">New booking</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="container">
      <div class="tg_new_booking_nsx tg_my_booking_nsx">
        <div class="text_my_booking_hd">
          <div class="text_my_booking_ft">
            <h3>予約番号：{{order.booking_id}}</h3>
            <p>お客様情報</p>
            <ul>
              <li>
                <label>
                  お名前
                </label>
                <strong>{{customer.name}}</strong>
              </li>
              <li>
                <label>
                  電話番号
                </label>
                <strong>{{customer.phone}}</strong>
              </li>
              <li>
                <label>
                  メールアドレス
                </label>
                <strong>{{customer.email}}</strong>
              </li>
            </ul>
          </div>
          <div class="text_my_booking_ft">
            <p>予約情報</p>
            <ul>
              <li>
                <label>
                  予約情報
                </label>
                <strong>{{order.description}}</strong>
              </li>
              <li>
                <label>
                  利用予定人員
                </label>
                <strong>{{order.people_number}}人</strong>
              </li>
              <li>
                <label>
                  利用日時
                </label>
                <strong>{{convertDate(order.date)}} {{order.start_time}} ~ {{order.end_time}}</strong>
              </li>
            </ul>
            <div class="tg_price_booking_nsx">
              <p>
                <span>利用料金</span>
                <strong>{{formatNumber(order.price_room)}}円</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="staff" v-if="order_staff.length">
            <div class="tg_my_price_hd">
            <h3>スタッフ/Staff</h3>
            <h5 v-for="(item, key) in order_staff" :key="key">
                <p>{{item.name}}</p>
                <span>
                <i>数量：{{item.us_quantity}}</i>
                <u>{{formatNumber(item.price)}}円</u>
                </span>
            </h5>
            </div>
            <div class="tg_price_booking_nsx">
            <p>
                <span>スタッフ料金</span>
                <strong>{{formatNumber(order.price_staff)}}円</strong>
            </p>
            </div>
        </div>
        <div class="equipment" v-if="order_equipment.length">
            <div class="tg_my_price_hd">
            <h3>機材レンタル</h3>
            <div v-for="(item, key) in order_equipment" :key="key">
                <h5>
                    <p>{{item.name}}</p>
                    <span>
                    <i>数量：{{item.us_quantity}}</i>
                    <u>{{formatNumber(item.price * item.us_quantity)}}円</u>
                    </span>
                </h5>
                <ul>
                    <li v-for="(v, k) in item.description" :key="k">
                        <div v-if="v.us_quantity != 0">
                            <span>{{v.equipment_name}}</span>
                            <i>数量：{{v.us_quantity}}</i>
                        </div>
                    </li>
                </ul>
            </div>
            </div>
            <div class="tg_price_booking_nsx">
            <p>
                <span>機材レンタル費</span>
                <strong>{{formatNumber(order.price_equipment)}}円</strong>
            </p>
            </div>
        </div>
        <div class="tg_total_price_nsx">
          <div class="text_total_price_ft">
            <p>
              <span>合計</span>
              <strong>{{formatNumber(order.price_room + order.price_staff + order.price_equipment)}}円</strong>
            </p>
          </div>
          <div class="bt_price_total_nsx_preview">
            <a href="javascript:void(0)" @click="goToBack()">入力画面に戻る</a>
            <a href="javascript:void(0)" @click="booking()">決済</a>
          </div>
          <div class="text_note_price_nsx">
            <p class="tg_sos">※ご利用当日ではスタッフを手配することが出来ません。必ずスタッフを選択してください。</p>
            <p class="tg_sos">※ご決済を15分過ぎた場合は、自動的にキャンセルになりますので、予めご了承ください</p>
            <p>※キャンセル料、期限は以下の通りです。</p>
            <ul>
              <li>30日～15日 30％</li>
              <li>14日～8日 50％</li>
              <li>7日前～当日 100％</li>
            </ul>
            <p>キャンセル後、30日以内に返金を振込させていただきます。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";

import flatpickr from "flatpickr";
import { ENV } from "@/core/config/env";
import { GET_EQUIPMENT, CREATE_BOOKING, CHECK_TIME_BOOKING, PREVIEW_BOOKING } from "@/core/services/store/booking.module";
import { STATUS_CODE, STAFF_EQUIPMENT } from "@/core/config/constant";

const { AUTHENTICATE, ERROR_SERVER, SUCCESS, BAD_REQUEST } = STATUS_CODE;
const { STAFF, EQUIPMENT } = STAFF_EQUIPMENT;

export default {
    name: "BookingDetail",
    data() {
        return {
            data: {},
            customer: {},
            equipment: {},
            order: {},
            staff: {},
            price_room: 0,
            price_staff: 0,
            price_equipment: 0,
            order_staff: [],
            order_equipment: [],
        }
    },

    computed: {
        ...mapGetters(['getOrder'])
    },

    watch: {
      getOrder: {
        handler() {
          this.dataAction();
        },
        immediate: true,
        // deep: true
      }
    },

    created() {
      this.dataAction();
    },

    methods: {
        dataAction() {
          let data = this.data = this.getOrder;
          this.customer = data.customer;
          this.equipment = data.equipment;
          this.order = data.order;
          this.staff = data.staff;
          this.order_staff = data.order_staff;
          this.order_equipment = data.order_equipment;
        },

        convertDate(date) {
            let arrDate = date.split("-");
            return arrDate[0] + " 年 " + arrDate[1] + " 月 " + arrDate[2] + "日";
        },

        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        },

        goToBack () {
            this.$router.push({name: 'booking', params: {back: 'preview'}})
        },

        async booking () {
          this.loading = true;
          this.customer.phone = this.customer.phone.replaceAll('(', '').replaceAll(')', '').replaceAll('.', '').replaceAll('-', '');
          let params = {
            customer: this.customer,
            order: this.order,
            order_staff: this.order_staff,
            order_equipment: this.order_equipment
          }
          let data = await this.$store.dispatch(CREATE_BOOKING, params);
          if (data.code == SUCCESS) {
            localStorage.removeItem("order");
            let url = data.data;
            window.location.href = url;
          } else if (data.code == BAD_REQUEST) {
            this.notifyError(this.$t('ER_13'))
          } else {
            this.notifyError(this.$t('errorServer'))
          }
          this.loading = false;
        }
    }
}

</script>
<style scoped>
.text_note_price_nsx>p.tg_sos {
  color: #000;
}
</style>
